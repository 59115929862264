import React, { FC, MouseEventHandler } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

interface IUlProps {
  children: string | JSX.Element | JSX.Element[]
  className?: string
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
}
interface ILiProps {
  children: string | JSX.Element | JSX.Element[]
  className?: string
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  onMouseMove?: MouseEventHandler
}
interface IOlProps {
  children: string | JSX.Element | JSX.Element[]
  className?: string
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
}

export const StyledListWrapper = styled.div`
  ul,
  ol {
    ${tw`list-outside pl-4`}
    & ul,
    & ol {
      ${tw`mt-0 mb-0`}
    }
  }
  ul {
    li {
      &::before {
        ${tw`bg-deepDarkBlue font-bold inline-block w-1.5 h-1.5 rounded-full relative absolute`}
        left: -0.8875rem;
        top: 0.7125rem;
        content: '';
      }
      li {
        &::before {
          content: '–';
          ${tw`bg-transparent font-normal`}
          left: -1rem;
          top: 0rem;
        }
      }
    }
  }
  ol {
    ${tw`list-decimal`}
  }
  li {
    ${tw`text-lg relative`}
    p {
      ${tw`block`}
      margin: 0;
      padding: 0;
    }
  }
`

const Ul: FC<IUlProps> = ({
  children,
  className,
  onMouseLeave,
  onMouseEnter,
}) => (
  <StyledListWrapper>
    <ul
      className={`${className}`}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </ul>
  </StyledListWrapper>
)

const Ol: FC<IOlProps> = ({
  children,
  className,
  onMouseLeave,
  onMouseEnter,
}) => (
  <StyledListWrapper>
    <ol
      className={`${className}`}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </ol>
  </StyledListWrapper>
)

const Li: FC<ILiProps> = ({
  children,
  className,
  onMouseLeave,
  onMouseEnter,
  onMouseMove,
}) => (
  <StyledListWrapper>
    <li
      className={`${className}`}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
    >
      {children}
    </li>
  </StyledListWrapper>
)

const List: Record<string, FC<IUlProps | ILiProps>> = { Ul, Li, Ol }

export default List
