import React, { FC } from 'react'
import styled from 'styled-components'

interface IPlusIconSignProps {
  width?: string
  color?: string
}

interface IPlusIconProps extends IPlusIconSignProps {
  className?: string
}

const PlusSign = styled.span<IPlusIconSignProps>`
  display: block;
  background: linear-gradient(
      ${(props) => props.color},
      ${(props) => props.color}
    ),
    linear-gradient(${(props) => props.color}, ${(props) => props.color});
  background-position: center;
  background-size: 100% ${(props) => props.width},
    ${(props) => props.width} 100%;
  background-repeat: no-repeat;
`

const PlusIcon: FC<IPlusIconProps> = ({ width, color, className }) => (
  <PlusSign
    className={`box-content ${className}`}
    width={width}
    color={color}
  />
)

PlusIcon.defaultProps = {
  width: '2px',
  color: 'black',
}

export default PlusIcon
