// i18next-extract-mark-ns-start law-changes

import React, { FC, useEffect, useState } from 'react'
import LawChangesList from 'src/components/law-changes/LawChangesList'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import RelatedDocuments from 'src/components/document/RelatedDocuments'
import { IRelatedDocument } from 'src/components/document/document-types'
import config from 'src/../tailwind.config'
import Subtitle from 'src/components/typography/Subtitle'
import ThinHeader from 'src/components/layout/ThinHeader'
import IframeLoadingSpinner from '../ui/IframeLoadingSpinner'

export interface LawChangeDetail {
  id: string
  title: string
  hideAmendedTextHeader: boolean
  amendedTextHeader: string
  amendedText: string
  explanation: string
  buttonText?: string
  buttonLink?: string
  swapBlocks: boolean
}

export interface LawChange {
  season: {
    season: string
    slug: string
  }
  id: string
  affectsMultipleLaws: boolean
  changeTitle: string
  changeSummary: string
  changeDetails: LawChangeDetail[]
}

export interface LawChangesData {
  multiLawChanges: LawChange[]
  singleLawChanges: LawChange[]
  relatedDocuments: IRelatedDocument[]
  showPresentation23n24: boolean
  showPresentation24n25: boolean
}

const LawChanges: FC<LawChangesData> = ({
  multiLawChanges,
  singleLawChanges,
  relatedDocuments,
  showPresentation23n24,
  showPresentation24n25,
}) => {
  const { t } = useTranslation('law-changes')
  const [isLoading, setIsLoading] = useState(true)

  const hideLoaderWhenLoaded = () => {
    const presentation = document.getElementById('presentation') as HTMLIFrameElement

    if (!presentation) {
      return
    }

    presentation.addEventListener('load', () => {
      const checkerInLoop = setInterval(() => {
        if (!presentation.contentWindow) {
          clearInterval(checkerInLoop)
          return
        }
        const imagesInside = presentation.contentWindow.document.getElementsByClassName(
          'img-responsive'
        )

        if (imagesInside.length) {
          clearInterval(checkerInLoop)
          setIsLoading(false)
        }
      }, 500)
    })
  }

  useEffect(() => {
    hideLoaderWhenLoaded()
  })

  return (
    <div className="mb-14">
      {showPresentation23n24 && (
        <div>
          <ThinHeader>{t('Full presentation')}</ThinHeader>
          <div
            style={{
              overflow: 'hidden',
              position: 'relative',
              height: 0,
              padding: '1px',
              paddingBottom: '56.25%',
            }}
            className="w-full mb-12"
          >
            <iframe
              id="presentation"
              style={{
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
                position: 'absolute',
              }}
              title="Law changes presentation"
              src={t('PRESENTATION_PATH_23n24')}
              frameBorder="0"
              allow="fullscreen"
            />
            <IframeLoadingSpinner isLoading={isLoading} />
          </div>
        </div>
      )}
      {showPresentation24n25 && (
        <div>
          <ThinHeader>{t('Full presentation')}</ThinHeader>
          <div
            style={{
              overflow: 'hidden',
              position: 'relative',
              height: 0,
              padding: '1px',
              paddingBottom: '56.25%',
            }}
            className="w-full mb-12"
          >
            <iframe
              id="presentation"
              style={{
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
                position: 'absolute',
              }}
              title="Law changes presentation"
              src={t('PRESENTATION_PATH_24n25')}
              frameBorder="0"
              allow="fullscreen"
            />
            <IframeLoadingSpinner isLoading={isLoading} />
          </div>
        </div>
      )}
      {multiLawChanges.length ? (
        <LawChangesList
          title={t('Changes Affecting Multiple Laws')}
          lawChanges={multiLawChanges}
          className="mb-24"
        />
      ) : (
        <></>
      )}
      {singleLawChanges.length ? (
        <LawChangesList
          title={t('Individual Law changes')}
          lawChanges={singleLawChanges}
          className="mb-24"
        />
      ) : (
        <></>
      )}
      {relatedDocuments?.length && (
        <>
          <Subtitle style={{ marginBottom: 0 }}>{t('Documents')}</Subtitle>
          <RelatedDocuments
            className="mb-12"
            items={relatedDocuments}
            color={config.theme.extend.colors.navyBlueGray}
            noTopBorder
          />
        </>
      )}
    </div>
  )
}

export default LawChanges
