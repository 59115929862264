import React, { FC } from 'react'
import { CmsHtmlContentGrayUnderline } from 'src/components/typography/CmsHtmlContentFormats'
import SemiRoundedButton from 'src/components/ui/SemiRoundedButton'
import { StyledListWrapper } from 'src/components/typography/List'
import SectionTitle from 'src/components/typography/SectionTitle'
import { paragraphStyles } from 'src/components/typography/Paragraph'
import styled from 'styled-components'
import tw from 'twin.macro'
import { LawChangeDetail } from 'src/components/law-changes/LawChanges'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'

interface ILawChangeDetailItemProps {
  changeDetail: LawChangeDetail
}

const StyledLawChangeBreadcrumb = styled.div`
  ${paragraphStyles}
  ${tw`mb-0 mt-7 mb-7`}
  p {
    ${tw`bg-paleBlue inline px-5 py-0.5 mb-1`}
    -webkit-box-decoration-break: clone;
  }
`

const LawChangesHtmlContentContainer = styled.div`
  ${tw`px-5`}
  blockquote {
    ${tw`-ml-5`}
  }
`

const LawChangeDetailItem: FC<ILawChangeDetailItemProps> = ({
  changeDetail,
}) => {
  const { t } = useTranslation('law-changes')

  const explanationBlock = changeDetail.explanation && (
    <>
      <SectionTitle className="px-5">{`${t('Explanation')}:`}</SectionTitle>
      <StyledListWrapper>
        <CmsHtmlContentGrayUnderline
          className="text-deepDarkBlue px-5"
          dangerouslySetInnerHTML={{ __html: changeDetail.explanation }}
        />
      </StyledListWrapper>
    </>
  )

  return (
    <div className="text-left mt-11 mb-11">
      {changeDetail.swapBlocks && explanationBlock}
      {changeDetail.title && (
        <StyledLawChangeBreadcrumb
          dangerouslySetInnerHTML={{ __html: changeDetail.title }}
        />
      )}
      {changeDetail.amendedText && (
        <>
          {!changeDetail.hideAmendedTextHeader && (
            <div className="px-5">
              <SectionTitle>
                {`${changeDetail.amendedTextHeader || t('Amended text')}:`}
              </SectionTitle>
            </div>
          )}
          <LawChangesHtmlContentContainer>
            <StyledListWrapper>
              <CmsHtmlContentGrayUnderline
                dangerouslySetInnerHTML={{ __html: changeDetail.amendedText }}
              />
            </StyledListWrapper>
          </LawChangesHtmlContentContainer>
        </>
      )}
      {!changeDetail.swapBlocks && explanationBlock}

      {changeDetail.buttonText && (
        <div className="px-5 mt-10">
          <Link to={changeDetail.buttonLink}>
            <SemiRoundedButton>{changeDetail.buttonText}</SemiRoundedButton>
          </Link>
        </div>
      )}
    </div>
  )
}

export default LawChangeDetailItem
