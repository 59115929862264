import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { FC } from 'react'
import LoaderSpinner from './LoaderSpinner'

interface IIframeLoadingSpinnerProps {
  className?: string
  isLoading: boolean
}

const IframeLoadingSpinner: FC<IIframeLoadingSpinnerProps> = ({
  className,
  isLoading,
}) => {
  const { t } = useTranslation('law-changes')
  return (
    <div
      className={`
        ${className} 
        ${isLoading ? 'block' : 'hidden'}
        absolute inset-0 flex items-center justify-center flex-wrap flex-col
      `}
    >
      <LoaderSpinner />
      <p className="w-full text-center">{t('Loading')}...</p>
    </div>
  )
}

IframeLoadingSpinner.defaultProps = {
  className: '',
}

export default IframeLoadingSpinner
