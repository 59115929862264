import React, { FC } from 'react'
import styled from 'styled-components'
import config from 'src/../tailwind.config'
import tw from 'twin.macro'
import resolveConfig from 'tailwindcss/resolveConfig'

const fullCfg = resolveConfig(config)

interface ITextDecorationProps {
  highlightColor: string
}

const TextDecoration = styled.span<ITextDecorationProps>`
  ${tw`whitespace-pre-line`}
  & > * {
    ${tw`inline px-2 md:px-6`}
    -webkit-box-decoration-break: clone;
    background-image: linear-gradient(
      to bottom,
      transparent 0% 54%,
      ${({ highlightColor }) => highlightColor} 54% 82.625%,
      transparent 82.625% 100%
    );
    @media (min-width: ${fullCfg.theme.screens.sm}) {
    }
  }
`

interface ITextHightlightProps {
  highlightColor?: string
  children: string | JSX.Element | JSX.Element[]
  className: string
}

const TextHightlight: FC<ITextHightlightProps> = ({
  highlightColor,
  children,
  className,
}) => {
  const isArray = Array.isArray(children)

  if (isArray) {
    const arrayOfChildrens = children as Array<JSX.Element>
    return (
      <>
        {arrayOfChildrens.map((singleElement) => (
          <TextDecoration
            key={singleElement.props.children}
            highlightColor={highlightColor}
            className={`${className} block`}
          >
            {singleElement}
          </TextDecoration>
        ))}
      </>
    )
  }

  return (
    <TextDecoration
      highlightColor={highlightColor}
      className={`${className} block w-full`}
    >
      {children}
    </TextDecoration>
  )
}

TextHightlight.defaultProps = {
  highlightColor: config.theme.extend.colors.brightYellow,
}

export default TextHightlight
