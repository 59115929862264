import React, { FC } from 'react'
import { LawChangeDetail } from 'src/components/law-changes/LawChanges'
import LawChangeDetailItem from 'src/components/law-changes/LawChangeDetailItem'

interface ILawChangeDetailsListProps {
  lawChangeDetails: LawChangeDetail[]
}

const LawChangeDetailsList: FC<ILawChangeDetailsListProps> = ({
  lawChangeDetails,
}) => {
  const details = lawChangeDetails.map((detail) => (
    <LawChangeDetailItem key={detail.id} changeDetail={detail} />
  ))
  return <div className="">{details}</div>
}
export default LawChangeDetailsList
