// i18next-extract-mark-ns-start law-changes

import React, { FC } from 'react'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import PageTitle from 'src/components/typography/PageTitle'
import LawChanges, { LawChange } from 'src/components/law-changes/LawChanges'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IRelatedDocument } from 'src/components/document/document-types'

interface ILawChangesPageContext {
  pageContext: {
    season: string
    remoteSlug: string
    multiLawChanges: LawChange[]
    singleLawChanges: LawChange[]
    canonicalUrl?: string
    relatedDocuments?: IRelatedDocument[]
  }
}

const IndexPage: FC<ILawChangesPageContext> = ({
  pageContext: {
    season,
    remoteSlug,
    multiLawChanges,
    singleLawChanges,
    canonicalUrl,
    relatedDocuments,
  },
}) => {
  const { t } = useTranslation('law-changes')
  return (
    <>
      <Helmet>
        <title>{t('Law changes')}</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      </Helmet>
      <Layout>
        <div className="min-h-screen bg-white text-deepDarkBlue">
          <div className="container text-center pt-8 pb-12 sm:pt-14 md:pt-24 relative left-0 right-0">
            <PageTitle>
              <span>
                {t('Law changes')}
                {season.substring(2)}
              </span>
            </PageTitle>
            <LawChanges
              multiLawChanges={multiLawChanges}
              singleLawChanges={singleLawChanges}
              relatedDocuments={relatedDocuments}
              showPresentation23n24={remoteSlug === '2023-24'}
              showPresentation24n25={remoteSlug === '2024-25'}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
