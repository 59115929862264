import React, { FC, useState } from 'react'
import { LawChangeDetail } from 'src/components/law-changes/LawChanges'
import LawChangeDetailsList from 'src/components/law-changes/LawChangeDetailsList'
import HeightAnitmation from 'src/components/layout/HeightAnimation'
import PlusIcon from 'src/components/icons/PlusIcon'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import themeColors from 'src/helpers/theme-colors'
import { StyledListWrapper } from 'src/components/typography/List'
import styled from 'styled-components'
import tw from 'twin.macro'

interface ILawChangeItemProps {
  id: string
  changeTitle: string
  changeSummary: string
  changeDetails: LawChangeDetail[]
}

const LawChangeSummaryContent = styled.div`
  *:last-child {
    ${tw`mb-0`}
  }
`

const StyledLawChange = styled.div`
  &:first-child {
    border-top: 0;
  }
  &:last-child {
    ${tw`border-b-2`}
  }
`

const LawChangeItem: FC<ILawChangeItemProps> = ({
  changeTitle,
  changeSummary,
  changeDetails,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  const backgroundColorAnimation = `transition-colors ease-in-out duration-500 ${
    isOpen ? 'bg-brightYellow' : 'bg-paleBlue'
  }`
  const rotateAnimation = `transition-transform ease-in-out duration-500${
    isOpen ? ' transform rotate-45' : ''
  }`

  return (
    <>
      <StyledLawChange
        className={`flex items-center border-t-2 border-deepDarkBlue pr-3 ${backgroundColorAnimation}`}
        onClick={toggleOpen}
        onKeyPress={toggleOpen}
        role="button"
        tabIndex={0}
      >
        <div className="flex-grow p-5 text-left text-deepDarkBlue">
          <StyledListWrapper>
            <CmsHtmlContent
              className="font-bold"
              dangerouslySetInnerHTML={{ __html: changeTitle }}
            />
            <LawChangeSummaryContent>
              <CmsHtmlContent
                className="font-light"
                dangerouslySetInnerHTML={{ __html: changeSummary }}
              />
            </LawChangeSummaryContent>
          </StyledListWrapper>
        </div>
        <button
          type="button"
          className={`${changeDetails.length ? '' : 'invisible '}`}
        >
          <PlusIcon
            className={`w-8 h-8 ${rotateAnimation}`}
            width="1.5px"
            color={themeColors.grayBlue}
          />
        </button>
      </StyledLawChange>
      <HeightAnitmation isOpen={isOpen}>
        <LawChangeDetailsList lawChangeDetails={changeDetails} />
      </HeightAnitmation>
    </>
  )
}

export default LawChangeItem
