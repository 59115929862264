import React, { FC } from 'react'
import { LawChange } from 'src/components/law-changes/LawChanges'
import LawChangeItem from 'src/components/law-changes/LawChangeItem'
import Subtitle from 'src/components/typography/Subtitle'

interface ILawChangesListProps {
  title: String
  lawChanges: LawChange[]
  className?: string
}
const LawChangesList: FC<ILawChangesListProps> = ({
  title,
  lawChanges,
  className,
}) => {
  const items = lawChanges.map((lawChange) => (
    <LawChangeItem
      key={lawChange.id}
      id={lawChange.id}
      changeTitle={lawChange.changeTitle}
      changeSummary={lawChange.changeSummary}
      changeDetails={lawChange.changeDetails}
    />
  ))
  return (
    <div className={className}>
      <Subtitle noMargin>{title}</Subtitle>
      <div>{items}</div>
    </div>
  )
}

export default LawChangesList
