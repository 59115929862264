import React, { FC } from 'react'
import 'src/components/ui/loader-spinner.css'

interface ILoaderSpinnerProps {}

const LoaderSpinner: FC<ILoaderSpinnerProps> = () => (
  <div className="loader-spinner">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default LoaderSpinner
